@layer base {
    *,
    body {
        @apply font-montserrat antialiased not-italic;
    }
}


// Mobile screen.

h3 {
	@apply text-[1.728rem];
}


// Medium Screen
@screen md {
	h3 {
		@apply text-[1.728rem];
	}
}

// Large Screen
@screen lg {
	h3 {
		@apply text-4xl;
	}
}
