@tailwind base;
@tailwind components;
@tailwind utilities;

@import './typography.scss';
@import 'node_modules/@glidejs/glide/src/assets/sass/glide.core';

@layer components {
  .container {
    @apply max-w-[1140px];
  }

  .text-shadow {
    text-shadow: 0px 1px 7px rgba(0, 0, 0, 0.59);
  }

  .button-gold-solid {
    @apply uppercase text-[#FFFFFF] text-center bg-brand-gold hover:bg-brand-gold-hover focus:ring-brand-secondary-dark focus:ring-2 focus:outline-none;
  }

  .button-black-outline {
    @apply bg-white text-black border border-black hover:bg-brand-white-hover hover:text-white focus:ring-brand-primary focus:ring-2 focus:outline-none;
  }

  .giftbox-modal #polls-platform-poll-button-cart {
    display: none !important;
  }
}

@layer utilities {
}

input[type='number'] {
  -moz-appearance: textfield; /*For FireFox*/

  &::-webkit-inner-spin-button {
    /*For Webkits like Chrome and Safari*/
    -webkit-appearance: none;
    margin: 0;
  }
}

.image-brand-border {
  border: solid 3px #b18d56 !important;
}

.have-menu-items {
  &:after {
    content: '\f140';
  }
}

.inner-html {
  ul {
    @apply list-disc mx-4;
  }

  .elementor-hidden-mobile,
  .elementor-hidden-phone {
    @apply hidden;
  }

  .elementor-hidden-tablet {
    @apply md:hidden;
  }

  .elementor-hidden-desktop {
    @apply xl:hidden;
  }

  .elementor-5242
    .elementor-element.elementor-element-623fe16a
    > .elementor-widget-container {
    margin-top: 0 !important;
  }

  .elementor.elementor-5242 {
    @apply mt-0;
  }

  .elementor-element-2347fcc3 {
    @apply hidden;
  }

  .dii-wrapper,
  .elementor-5242 {
    @apply overflow-x-auto w-full;
  }
}

.delivery-important-information {
  // box-shadow: 0px 0px 26px rgba(0, 0, 0, 0.25);
  @apply table-auto overflow-scroll min-w-[650px];

  thead tr th {
    background: #b18d56;
    color: #fff;
    font-weight: 500;
    padding: 13px 23px;
  }

  tbody tr {
    td {
      padding: 13px 23px;
      text-align: left;

      p:last-of-type {
        margin-bottom: 0;
      }
    }

    &:nth-child(even) td {
      background-color: #f1f1ef;
    }

    &.important td:nth-child(2) {
      color: #ca1237;
    }
  }
}

.taxonomy-description-wrapper {
  @apply text-xs font-normal my-9 border-t border-black pt-5 leading-6;

  & > h2,
  & > h1 {
    @apply font-playfairdisplay italic text-3xl my-2;
  }

  & > p {
    @apply text-sm font-normal;
  }

  a {
    @apply text-brand-primary underline;
  }
}

.gb-reward-bg {
  background-repeat: no-repeat;
  height: 196px;
  background-position: 78% 100%;
  background-size: 340% auto;
}

.banner-promo-catalog-item {
  display: inline-grid;
  grid-column-end: span 2;
}

@screen md {
  .gb-reward-bg {
    height: 371px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .byoh-grid-order {
    order: 3 !important;
  }

  .bulk-order-grid-item {
    order: 7 !important;
  }

  .second-catalog-item,
  .valentines-day {
    order: 3 !important;
  }

  .banner-promo-catalog-item {
    grid-column-end: span 3;
  }
}
